@tailwind base;
@tailwind components;
@tailwind utilities;
body{
	background-color: #121212;
	
}
/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hide::-webkit-scrollbar {
	display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .scrollbar-hide {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }
 /* Scrollbar width */
::-webkit-scrollbar {
	width: 1px;    /* For vertical scrollbar */
	height: 1px;   /* For horizontal scrollbar */
  }
  
  /* Scrollbar thumb (handle) */
  ::-webkit-scrollbar-thumb {
	background: #285ef4;
	border-radius: 1px;
  }
  
  /* Scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #285ef4;
  }
  
  /* Horizontal scrollbar */
  ::-webkit-scrollbar-horizontal {
	height: 0px;  /* Sets height for the horizontal scrollbar */
  }
  
  /* Horizontal scrollbar thumb (handle) */
  ::-webkit-scrollbar-thumb:horizontal {
	background: #121212;
	background: transparent; 
	
	border-radius: 0px;
  }
  
  /* Horizontal scrollbar thumb on hover */
  ::-webkit-scrollbar-thumb:horizontal:hover {
	background: #121212;
	background: transparent; 
  }
  